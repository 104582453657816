import React, { FC } from 'react'
import tw from 'twin.macro'

const BurgerIcon: FC = () => {
  return (
    <svg css={[tw`fill-current`]} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
      <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
    </svg>
  )
}

export default BurgerIcon
