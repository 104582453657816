import React, { FC } from 'react'

const Malt: FC<{}> = props => {
  return (
    <svg width="24" height="24" viewBox="0 0 92 93" {...props}>
      <g fillRule="evenodd" fill="currentColor">
        <path d="M34.561 78.975c0 7.844 5.104 12.282 11.45 12.306.016 0-.016 0 0 0 6.324.002 11.54-5.976 11.566-12.306L46.003 67.257 34.561 78.975zM30.494 12.753a11.432 11.432 0 00-8.062-3.546c-3.094-.058-5.99 1.068-8.21 3.192-4.584 4.39-4.742 11.688-.354 16.272l1.718 1.794 24.332.104 3.916-3.882-13.34-13.934zm47.859 49.986l-1.718-1.794-24.332-.104-3.916 3.882 13.34 13.934c4.388 4.58 11.69 4.74 16.272.354 4.582-4.39 4.742-11.688.354-16.272M79.246 34.335L55.92 57.255l24.742.106h.05c3.056 0 5.932-1.182 8.104-3.334A11.448 11.448 0 0092.22 45.9c.026-6.344-4.974-11.728-12.974-11.566m-67.688-.284h-.054a11.425 11.425 0 00-8.098 3.334A11.439 11.439 0 000 45.508c-.014 3.074.578 11.556 13.16 11.556L36.3 34.156l-24.742-.106zM78.854 13.239c-4.468-4.506-11.768-4.54-16.274-.068L13.436 61.893c-4.506 4.47-4.538 11.77-.07 16.276 4.468 4.508 11.77 4.542 16.276.07l49.142-48.722c4.508-4.47 4.538-11.772.07-16.278M58.578 12.305c0-7.844-5.104-12.28-11.45-12.304-.016 0 .016 0 0 0C40.804 0 35.588 5.975 35.562 12.305l11.574 11.718 11.442-11.718z" />
      </g>
    </svg>
  )
}

export default Malt
